import ProductsContainer from "../../containers/ProductsContainer";
import Slider from "../../sliders/Slider";
import { Col, Row } from "antd";
import { ColTitleFrontPage, TitleFrontPage } from "./styled";
import useMovilSize from "../../../hooks/useMovilSize";
import { useContext, useEffect } from "react";
import { ProductsContext } from "../context/ProductsContext";
import FooterCustom from "../../footer/FooterCustom";
import PromotionalContainer from "../components/promotionalContainer/PromotionalContainer";
import { ContentCarousel } from "../../carouselProducts/components/style";
import CarouselCategorys from "../../carouselCategorys/components/CarouselCategorys";
// import CarouselSearchProducts from "../../carouselSearchProducts/components/CarouselSearchProducts";

const VeronaInitialPage = () => {
  const { MovilSize } = useMovilSize();
  const { categorysCrud, productsCrud } = useContext(ProductsContext);
  const { dataCategorys } = categorysCrud;
  const { getData } = productsCrud;

  console.log(dataCategorys);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Row style={{}}>
      <Col xs={24} md={24}>
        <Row justify="center">
          <Col xs={24} md={24}>
            <Slider />
          </Col>

          <Col xs={24} md={24}>
            <Row
              justify={"center"}
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <ColTitleFrontPage xs={24} md={10}>
                {/* <ContentTitleFrontPage justify="center" align="middle"> */}
                <TitleFrontPage MovilSize={MovilSize}>Novedades</TitleFrontPage>
                {/* </ContentTitleFrontPage> */}
              </ColTitleFrontPage>
              <Col
                xs={18}
                md={14}
                style={{ justifyContent: "center", alignSelf: "center" }}
              >
                {/* <CategorieSlider /> */}

                {/* <ProductsSearchAndCarouselCustom listMode />
                 */}
                <ContentCarousel>
                  <CarouselCategorys items={dataCategorys} autoPlay />
                </ContentCarousel>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={24}>
            <ProductsContainer filterRequired={false} />
          </Col>
        </Row>

        {/* <PromotionalContainer /> */}
      </Col>
    </Row>
  );
};

export default VeronaInitialPage;
