const API = {
  users: {
    root: "/users/",
  },
  products: {
    root: /products/,
    oulet: "oulet/",
    category: "category/",
  },
  sliders: {
    root: /sliders/,
  },
  categorys: {
    root: /categorys/,
  },
  garmentType: {
    root: /garmentType/,
  },
  references: {
    root: /references/,
  },
  orders: {
    root: /orders/,
  },
  payment: {
    root: /payment/,
  },
  codes: {
    root: /codes/,
    validate: "validate",
  },
  subscription: {
    root: /subscription/,
  },

  auth: {
    root: /auth/,
    signin: "signin",
    verifySession: "verifySession",
  },
};

export default API;
