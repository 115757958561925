import { Col, Row } from "antd";
import styled from "styled-components";

const FooterContainer = styled(Row)`
  /* background-color: #ffffff !important; */
  background-color: rgba(211, 211, 211, 0.5);
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  background: linear-gradient(
    to bottom right,
    rgba(169, 170, 162, 0.9),
    rgba(255, 255, 255, 0.8)
  );
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  min-height: 200px !important;
  justify-content: center;
  margin-top: 70px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
`;

const ContentIcon = styled(Col)`
  justify-content: center;
  text-align: center;
  color: #75777e;
  margin-top: 30px;
  a {
    color: #75777e;
    :hover {
      color: #75777e;
      opacity: 0.6;
    }
  }
`;

const ContentInfo = styled(Row)`
  justify-content: start;
  text-align: start;
  font-family: "Baloo Tamma 2", system-ui;
  font-weight: 500;
  margin-top: 70px;
  /* margin-bottom: 40px;  */
  min-height: 180px;

  .link {
    /* color: gray; */
    color: white;
    text-decoration: none;
    font-weight: none;
    font-size: 13px;
    font-family: Montserrat, sans-serif;
  }

  .ContentLinkOptions {
    margin-top: 10px;
  }
`;

const TitleInfo = styled.span`
  color: white;
  font-family: Montserrat, sans-serif;
`;
export { FooterContainer, ContentIcon, ContentInfo, TitleInfo };
