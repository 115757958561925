import { useState, useEffect } from "react";
import { ContentMessage, Labelmessage } from "./style";
import { Button, Col, Row } from "antd";
import useMovilSize from "../../hooks/useMovilSize";

const Messages = [
  {
    name: "suscribe",
    label: "Suscríbete a Verona y obtén 15% de descuento ",
    redirect: false,
  },
  {
    name: "send",
    label: "Envío gratis por compras superiores a $240.000",
    redirect: true,
  },
  {
    name: "newColection",
    label: "Accede a nuestra Nueva Colección",
    redirect: false,
  },
];

const ContentMessagesNav = () => {
  const [showMessage, setShowMessage] = useState(0);
  const { MovilSize } = useMovilSize();

  const handlePrev = () => {
    setShowMessage((prev) => (prev - 1 + Messages.length) % Messages.length);
  };

  const handleNext = () => {
    setShowMessage((prev) => (prev + 1) % Messages.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Col md={24} xs={24}>
      <ContentMessage justify={"center"}>
        <Col md={12}>
          <Row justify={"center"}>
            <Col md={2} xs={2}>
              <Button onClick={handlePrev}>{"<"}</Button>
            </Col>
            <Col md={20} xs={20}>
              <Row justify={"center"}>
                <Labelmessage MovilSize={MovilSize} key={showMessage}>
                  {Messages[showMessage]?.label}
                </Labelmessage>
              </Row>
            </Col>
            <Col md={2} xs={2}>
              <Button onClick={handleNext}>{">"}</Button>
            </Col>
          </Row>
        </Col>
      </ContentMessage>
    </Col>
  );
};

export default ContentMessagesNav;
