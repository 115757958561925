import { Row } from "antd";
import styled, { keyframes } from "styled-components";

const slideLeft = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 0.4;
  }
`;

const ContentMessage = styled(Row)`
  background-color: #212121;
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-top: 0px;
    background-color: transparent;
    color: white;
    border: none;
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const Labelmessage = styled.span`
  background-color: transparent;
  text-align: center;
  max-width: ${(props) => (props.MovilSize ? "300px" : "700px")};
  min-width: ${(props) => (props.MovilSize ? "300px" : "500px")};
  white-space: nowrap; 
  overflow: hidden;
  display: inline-block;
  animation: ${slideLeft} 2s ease-in-out; /* Aplicar la animación */
  color: white;
`;

export { ContentMessage, Labelmessage };
