import React, { Suspense, useState, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Col, Row, Spin } from "antd";
import {
  AuthContext,
  AuthProvider,
} from "../components/products/context/AuthContext";
import { ProductsProvider } from "../components/products/context/ProductsContext";
import { SlidersProvider } from "../components/sliders/context/SlidersContext";
import { RoutesApp } from "./routes";
import ProtectedRoute from "./ProtectedRoute";
import { Navbar } from "../ui/components/Navbar";
import SearchCustomNav from "../components/searchCustomNav/SearchCustomNav";
import VeronaInitialPage from "../components/products/pages/VeronaInitialPage";
import DrawerCart from "../components/drawer/DrawerCart";
import ContentMessagesNav from "../components/contentMessagesTopNav/ContentMessagesNav";
import FooterCustom from "../components/footer/FooterCustom";

const LoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <Spin size="large" />
  </div>
);

const AppRouter = () => {
  const [showContentSearch, setShowContentSearch] = useState(false);
  const { isLoading, error, dataUser } = useContext(AuthContext);

  if (isLoading) return <LoadingSpinner />;
  if (error) return <div>Error: {error}</div>;

  return (
    <ProductsProvider>
      <SlidersProvider>
        <Suspense fallback={<LoadingSpinner />}>
          <Navbar
            onShowModalSearch={setShowContentSearch}
            showContenSearch={showContentSearch}
            dataUSer={dataUser}
          />
          {showContentSearch ? (
            <>
              <SearchCustomNav
                onCloseModalSearch={setShowContentSearch}
                showContenSearch={showContentSearch}
              />
            </>
          ) : null}
          <Col xs={24} md={24} style={{ width: "100%" }}>
            <Routes>
              {RoutesApp.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected ? (
                      <ProtectedRoute allowedRoles={route.allowedRoles}>
                        <route.element />
                      </ProtectedRoute>
                    ) : (
                      <route.element />
                    )
                  }
                />
              ))}
              <Route
                path="/"
                element={
                  dataUser?.data?.user?.roles.length > 0 ? (
                    <Navigate to="/" replace />
                  ) : (
                    <VeronaInitialPage />
                  )
                }
              />
              <Route path="/unauthorized" element={<VeronaInitialPage />} />
            </Routes>

            <FooterCustom />
          </Col>
          <DrawerCart />
        </Suspense>
      </SlidersProvider>
    </ProductsProvider>
  );
};

const AppRouterWithAuth = () => (
  <AuthProvider>
    <AppRouter />
  </AuthProvider>
);

export default AppRouterWithAuth;
