import { useEffect, useState } from "react";

const useMovilSize = () => {
  const [dataWidth, setDataWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setDataWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const MovilSize = dataWidth < 800;
  const tabletMode = dataWidth > 750 && dataWidth < 1000;

  return { MovilSize, dataWidth, tabletMode };
};

export default useMovilSize;
