import axios from "axios";
import API from "../../utils/apis";
import baseUrl from "../../utils/apis/BaseUrl";

const ProductsService = () => {
  const getAllProducts = async ({ search, category, reference, color }) => {
    const url = `${baseUrl}${API.products.root}`;

    const params = {
      search,
      category,
      reference,
      color,
    };

    const getProducts = await axios.get(url, {
      params: params,
    });

    return {
      data: getProducts.data,
    };
  };

  const getProductsBydiscount = async ({
    search,
    category,
    reference,
    color,
  }) => {
    const url = `${baseUrl}${API.products.root}${API.products.discounted}`;

    const params = {
      search,
      category,
      reference,
      color,
    };

    const getProducts = await axios.get(url, {
      params: params,
    });

    return {
      data: getProducts.data,
    };
  };

  const createProduct = async (product) => {
    await axios.post(`${baseUrl}${API.products.root}`, product);
  };

  // const getProduct = async (id) => {
  //   console.log(id);
  //   const getProduct = await axios.get(
  //     "https://api.lenceriaverona.com/api" + id
  //   );
  //   return {
  //     data: getProduct.data,
  //   };
  // };

  const getProduct = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}${API.products.root}${id}`);
      console.log(response);
      return {
        data: response.data,
      };
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error;
    }
  };

  const getProductByCategory = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}${API.products.root}${API.products.category}${id}`
      );
      console.log(response);
      return {
        data: response.data,
      };
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error;
    }
  };

  const editProduct = async (id, product) => {
    const { data } = await axios.put(
      `${baseUrl}${API.products.root}${id}`,
      product
    );

    return { data };
  };

  const deleteProduct = async (id) => {
    console.log(id);
    await axios.delete(`${baseUrl}${API.products.root}${id}`);
  };

  return {
    getAllProducts,
    deleteProduct,
    editProduct,
    getProduct,
    createProduct,
    getProductsBydiscount,
    getProductByCategory,
  };
};
export default ProductsService;
