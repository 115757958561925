import React, { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../context/ProductsContext";
import { ArrowDown, Sliders } from "react-feather";
import { Plus } from "react-feather";

import { HeartOutlined } from "@ant-design/icons";
import {
  ButtonsContainer,
  CardProduct,
  ContenCustom,
  ContentProducts,
  ImageContainer,
  ItemName,
  ItemPrice,
  ItemPriceOff,
  NewLabel,
  LabelOff,
  RowNew,
  RowOff,
  RowAddToCart,
} from "./style/styleProducts";

import { replace, useNavigate } from "react-router-dom";
import ButtonAddToCart from "../../buttonsCustom/ButtonAddToCart";
import { Button, Col, Row } from "antd";

import useMovilSize from "../../../hooks/useMovilSize";
import SearchCustom from "../../searchCustom/SearchCustom";
import DrawerFilter from "../../drawerFilter/DrawerFilter";
import { CategorysConstant } from "../../../constants/categoryConstant";
import {
  ButtonSelectedType,
  ButtonSize,
  ContainerSizes,
  ContenColorInitial,
  ContentColor,
  ContentInitialSizes,
  ContentNamePiece,
  TextNameSelectPiece,
} from "./style";
import styled from "styled-components";

const Products = ({
  products,
  searchMode = false,
  onShowFilter,
  showFilter,
  filterRequired = false,
}) => {
  const { shoppingCart } = useContext(ProductsContext);
  const { onAddToCart } = shoppingCart;
  const [showModalFilter, setShowModalFilter] = useState(false);
  const { dataWidth, MovilSize } = useMovilSize();
  const uploadsPath = process.env.REACT_APP_IMAGES_URL;

  const [captureProduct, setCaptureProduct] = useState({
    product: {},
    selectedPiece: [],
    selectedTypeSizes: [],
    currentPieceIndex: 0,
    selectedType: null,
    selectedTypeName: null,
  });
  const navigate = useNavigate();
  const [isHoverId, setIsHoverId] = useState();

  const onCaptureProduct = (product) => {
    setCaptureProduct({
      product: {},
      selectedPiece: [],
      selectedTypeSizes: [],
      currentPieceIndex: 0,
      selectedType: null,
      selectedTypeName: null,
    });
    setCaptureProduct((prevState) => ({
      ...prevState,
      product: product,
    }));
  };

  console.log(products);

  const onShowModalFilter = () => {
    if (MovilSize && showFilter) {
      setShowModalFilter(true);
    }
    onShowFilter(true);
  };

  const onCloseDrawer = () => {
    setShowModalFilter(false);
  };

  const onCaptureSizes = (piece, size) => {
    if (
      captureProduct.currentPieceIndex < captureProduct.product.pieces.length
    ) {
      setCaptureProduct((prevState) => ({
        ...prevState,
        selectedPiece: [
          ...prevState.selectedPiece,
          {
            piece: piece.piece,
            size,
            type: prevState.selectedType,
            typeName: prevState.selectedTypeName,
          },
        ],
        currentPieceIndex: prevState.currentPieceIndex + 1,
      }));
    } else {
      console.warn("currentPieceIndex out of bounds");
    }
  };

  const handleMouseEnter = (productId) => {
    setIsHoverId(productId);
  };

  const handleMouseLeave = () => {
    setIsHoverId(null);
  };

  const handleTypeChange = (typeId, typeName) => {
    setCaptureProduct((prevState) => {
      const currentPiece =
        prevState.product.pieces[prevState.currentPieceIndex];
      const selectedType = currentPiece?.typesPieces.find(
        (type) => type._id === typeId
      );
      if (!selectedType) {
        console.warn("Tipo de pieza no encontrado");
        return prevState;
      }
      return {
        ...prevState,
        selectedType: typeId,
        selectedTypeName: typeName,
        selectedTypeSizes: selectedType?.sizes || [],
      };
    });
  };

  useEffect(() => {
    const currentPiece =
      captureProduct?.product?.pieces?.[captureProduct.currentPieceIndex];
    if (currentPiece) {
      if (currentPiece.typesPieces?.length === 1) {
        const type = currentPiece.typesPieces[0];
        handleTypeChange(type._id, type.typePieceName);
      } else {
        setCaptureProduct((prevState) => ({
          ...prevState,
          selectedType: null,
          selectedTypeName: null,
          selectedTypeSizes: [],
        }));
      }
    }
  }, [captureProduct.currentPieceIndex, captureProduct.product]);

  useEffect(() => {
    if (
      captureProduct?.product?.pieces?.length ===
      captureProduct?.selectedPiece?.length
    ) {
      const newProduct = {
        ...captureProduct.product,
        selectedPiece: captureProduct.selectedPiece,
      };
      onAddToCart(newProduct);
      setCaptureProduct({
        product: {},
        selectedPiece: [],
        selectedTypeSizes: [],
        currentPieceIndex: 0,
        selectedType: null,
        selectedTypeName: null,
      });
    }
  }, [captureProduct.selectedPiece]);

  const redirectToProductDetails = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <>
      <ContenCustom justify="center">
        <Col xs={24} md={dataWidth < 1100 ? 24 : dataWidth < 1150 ? 24 : 18}>
          <ContentProducts justify="center" gutter={2}>
            {searchMode ? (
              <Col xs={24} md={24}>
                <Row style={{ justifyContent: "center" }}>
                  <SearchCustom modePage />
                </Row>
              </Col>
            ) : null}
            <Col xs={24} md={24}>
              {!showFilter || (searchMode && dataWidth < 1000) ? (
                <>
                  {filterRequired ? (
                    <Row justify={"end"}>
                      <Col>
                        <Button
                          onClick={onShowModalFilter}
                          className="btnFilter"
                          icon={<Sliders size={18} />}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  <DrawerFilter
                    show={showModalFilter}
                    onChange={onCloseDrawer}
                  />
                </>
              ) : null}
            </Col>

            {products.map((item) => {
              // const dataReplace

              const product = item.replaceItem ? item.newItemReplace : item;

              return (
                <Col
                  xs={12}
                  sm={12}
                  md={
                    searchMode && dataWidth > 730 ? 8 : dataWidth < 995 ? 8 : 6
                  }
                  key={product._id}
                >
                  <CardProduct justify="center" responsive={dataWidth < 480}>
                    <ImageContainer
                      onMouseEnter={() => handleMouseEnter(product._id)}
                      onMouseLeave={handleMouseLeave}
                      responsive={dataWidth < 480}
                    >
                      <Col xs={12} md={12} style={{ minHeight: "0px" }}>
                        <Row>
                          <Col md={12} xs={12}>
                            {product.category.some(
                              (cat) => cat._id === CategorysConstant.NEW.id
                            ) && (
                              <RowNew MovilSize={MovilSize}>
                                <NewLabel>
                                  {CategorysConstant.NEW.label}
                                </NewLabel>
                              </RowNew>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      {product.discountPercentage > 0 ? (
                        <Col xs={12} md={12} style={{ minHeight: "0px" }}>
                          <Row justify={"end"}>
                            <Col md={12} xs={12}>
                              <RowOff justify={"end"} MovilSize={MovilSize}>
                                <LabelOff>Off</LabelOff>
                                <ArrowDown className="icon" />
                              </RowOff>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}

                      <img
                        src={
                          isHoverId === product._id &&
                          product.imgs &&
                          product.imgs[0]
                            ? `${uploadsPath}${product?.imgs[1]?.url}`
                            : `${uploadsPath}${
                                product.imgs &&
                                product.imgs[0] &&
                                product?.imgs[0]?.url
                              }`
                        }
                        alt={product.description}
                        onClick={() => redirectToProductDetails(product._id)}
                      />

                      <Col xs={24} md={24} style={{ minHeight: "0px" }}>
                        <Row justify={"end"}>
                          <Col md={6} xs={8}>
                            <RowAddToCart justify={"end"} MovilSize={MovilSize}>
                              <ButtonAddToCart
                                onChange={() => onCaptureProduct(product)}
                              />{" "}
                            </RowAddToCart>
                          </Col>
                        </Row>
                      </Col>
                    </ImageContainer>

                    {captureProduct.product?._id === product._id ? (
                      <ContainerSizes>
                        <Col xs={24}>
                          {product.pieces.length > 0 && (
                            <Col xs={24}>
                              {product.pieces[
                                captureProduct.currentPieceIndex
                              ] && (
                                <ContentInitialSizes>
                                  {product.pieces[
                                    captureProduct.currentPieceIndex
                                  ].typesPieces.length > 1 ? (
                                    <Col md={24} xs={24}>
                                      <Row>
                                        <Col>
                                          <span>Tipo de </span>
                                          <span>
                                            {
                                              product.pieces[
                                                captureProduct.currentPieceIndex
                                              ].piece
                                            }
                                          </span>
                                        </Col>
                                        {product.pieces[
                                          captureProduct.currentPieceIndex
                                        ].typesPieces.map((type) => (
                                          <Col xs={12} md={12} key={type._id}>
                                            <ButtonSelectedType
                                              type={
                                                captureProduct.selectedType ===
                                                type._id
                                                  ? "primary"
                                                  : "default"
                                              }
                                              onClick={
                                                () =>
                                                  handleTypeChange(
                                                    type._id,
                                                    type.typePieceName
                                                  ) // Captura el nombre del tipo
                                              }
                                              backgroundColor={
                                                captureProduct.selectedType ===
                                                type._id
                                                  ? "black"
                                                  : "#e9e9e9"
                                              }
                                              color={
                                                captureProduct.selectedType ===
                                                type._id
                                                  ? "white"
                                                  : "black"
                                              }
                                            >
                                              <span>{type.typePieceName}</span>
                                            </ButtonSelectedType>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Col>
                                  ) : null}

                                  {/* Mostrar el nombre de la pieza solo si hay un único tipo */}
                                  {product.pieces[
                                    captureProduct.currentPieceIndex
                                  ].typesPieces.length === 1 && (
                                    <ContentNamePiece xs={24}>
                                      <TextNameSelectPiece justify="center">
                                        <Col xs={24}>
                                          <span>
                                            {
                                              product.pieces[
                                                captureProduct.currentPieceIndex
                                              ].piece
                                            }
                                          </span>
                                        </Col>
                                      </TextNameSelectPiece>
                                    </ContentNamePiece>
                                  )}

                                  <Col xs={24}>
                                    <Row gutter={3} justify="center">
                                      {/* Mostrar las tallas según el tipo seleccionado o el único tipo disponible */}
                                      {product.pieces[
                                        captureProduct.currentPieceIndex
                                      ].typesPieces.length === 1 ? (
                                        product.pieces[
                                          captureProduct.currentPieceIndex
                                        ].typesPieces[0].sizes.map((size) => (
                                          <Col xs={6} key={size._id}>
                                            <Row>
                                              <ButtonSize
                                                disabled={size.inventory <= 0}
                                                onClick={() =>
                                                  onCaptureSizes(
                                                    product.pieces[
                                                      captureProduct
                                                        .currentPieceIndex
                                                    ],
                                                    size
                                                  )
                                                }
                                              >
                                                <span>{size.name}</span>
                                              </ButtonSize>
                                            </Row>
                                          </Col>
                                        ))
                                      ) : captureProduct.selectedTypeSizes
                                          .length > 0 ? (
                                        captureProduct.selectedTypeSizes.map(
                                          (size) => (
                                            <Col xs={6} key={size._id}>
                                              <Row>
                                                <ButtonSize
                                                  disabled={size.inventory <= 0}
                                                  onClick={() =>
                                                    onCaptureSizes(
                                                      product.pieces[
                                                        captureProduct
                                                          .currentPieceIndex
                                                      ],
                                                      size
                                                    )
                                                  }
                                                >
                                                  <span>{size.name}</span>
                                                </ButtonSize>
                                              </Row>
                                            </Col>
                                          )
                                        )
                                      ) : captureProduct.selectedType ? (
                                        <Col xs={24}>
                                          <span>
                                            Selecciona un tipo de pieza para ver
                                            las tallas.
                                          </span>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  </Col>
                                </ContentInitialSizes>
                              )}
                            </Col>
                          )}
                        </Col>
                      </ContainerSizes>
                    ) : null}
                    <ButtonsContainer>
                      <Button>
                        <HeartOutlined style={{ fontSize: "18px" }} />
                      </Button>
                    </ButtonsContainer>
                    {captureProduct.product?._id === product._id ? null : (
                      <>
                        <ItemName>{product.name}</ItemName>
                        <Col md={24}>
                          <Row>
                            <ItemPrice
                              PriceOff={product?.discountPercentage > 0}
                            >
                              <span></span>
                              {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP",
                              }).format(product?.price)}{" "}
                              COP
                            </ItemPrice>
                            {product.discountPercentage > 0 ? (
                              <ItemPriceOff>
                                {new Intl.NumberFormat("es-CO", {
                                  style: "currency",
                                  currency: "COP",
                                }).format(
                                  product.price -
                                    (product.price *
                                      product.discountPercentage) /
                                      100
                                )}{" "}
                                COP
                              </ItemPriceOff>
                            ) : (
                              <ItemPriceOff style={{ color: "transparent" }}>
                                _
                              </ItemPriceOff>
                            )}
                          </Row>
                        </Col>

                        {/* <ButtonAddToCart
                          onChange={() => onCaptureProduct(product)}
                        /> */}
                      </>
                    )}
                  </CardProduct>
                </Col>
              );
            })}
          </ContentProducts>
        </Col>
      </ContenCustom>
    </>
  );
};

export default Products;
