import axios from "axios";
import baseUrl from "../../utils/apis/BaseUrl";
import API from "../../utils/apis";

const CategorysServices = () => {
  const getAllCategorys = async () => {
    const categorys = await axios.get(`${baseUrl}${API.categorys.root}`);
    return {
      data: categorys.data,
    };
  };

  const getCategoryById = async (id) => {
    const categorys = await axios.get(`${baseUrl}${API.categorys.root}${id}`);
    return {
      data: categorys.data,
    };
  };

  const CreateCategory = async (data) => {
    const categorys = await axios.post(
      `${baseUrl}${API.categorys.root}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return {
      data: categorys.data,
    };
  };

  const updateCategory = async (id, data) => {
    const categorys = await axios.put(
      `${baseUrl}${API.categorys.root}${id}`,
      data
    );
    return {
      data: categorys.data,
    };
  };

  const removeCategory = async (id) => {
    console.log(id);
    const categorys = await axios.delete(
      `${baseUrl}${API.categorys.root}${id}`
    );
    return {
      data: categorys.data,
    };
  };

  return {
    getAllCategorys,
    CreateCategory,
    removeCategory,
    updateCategory,
    getCategoryById,
  };
};

export default CategorysServices;
