import React, { useState } from "react";
// import { ContentLogo } from "../FormLogin/components/styled";
import { Col, Input, Row } from "antd";
import {
  ButtonSubscribe,
  ContentInitialsuscribe,
  ContentSubscribeFooter,
  DescriptionFormSuscribe,
  TitleFormSuscribe,
} from "./style";
// import {
//   WhatsAppOutlined,
//   InstagramOutlined,
//   PinterestOutlined,
// } from "@ant-design/icons";
// import { ContentIcon } from "../footer/style";
import useFormSubscribe from "./hooks/useFormSubscribe";

const FormSubscribe = () => {
  const { createSubscription } = useFormSubscribe();
  const [email, setEmail] = useState("");

  const onCreateSubscription = () => {
    const data = {
      email,
    };
    createSubscription(data);
    setEmail("");
  };

  const onCaptureEmail = (value) => {
    console.log(value.target.value);
    setEmail(value.target.value);
  };

  return (
    <ContentInitialsuscribe justify={"center"}>
      {/* <Col xs={24} md={14}>
        <ContentLogo>
          <img src="https://res.cloudinary.com/doe7vtjfk/image/upload/v1696743512/lenceria/362254836_643559124148919_680712196996114dd1421_n_mnbyld.png"></img>
        </ContentLogo>
      </Col> */}

      <Col xs={24} md={10}>
        <ContentSubscribeFooter justify={"center"}>
          <Col xs={16} md={20}>
            <TitleFormSuscribe>Suscribete a Verona</TitleFormSuscribe>
            <DescriptionFormSuscribe>
              Recibiras ofertas y lanzamientos exclusivos
            </DescriptionFormSuscribe>

            <Row justify={"center"}></Row>

            <Col xs={24}>
              <Input
                placeholder="Ingresa tu correo"
                onChange={onCaptureEmail}
                value={email}
              />
              <Row className="ButtonSeeCart">
                <ButtonSubscribe
                  onClick={onCreateSubscription}
                  // onClick={onHandleCart}
                >
                  <span>Suscribirse</span>
                </ButtonSubscribe>
              </Row>
            </Col>
          </Col>
        </ContentSubscribeFooter>
      </Col>

      {/* <Col xs={24} md={14}>
        <Row justify={"center"} style={{ marginBottom: "20px" }}>
          <Col xs={24} md={8}>
            <Row justify={"center"}>
              <ContentIcon md={8} xs={8}>
                <a
                  href="https://api.whatsapp.com/send?phone=573246097414&text=Bienvenid%40s%20a%20la%20l%C3%ADnea%20de%20atenci%C3%B3n%20verona%20%E2%98%80%EF%B8%8F%2C%20ser%C3%A1%20un%20gusto%20%20tomar%20tu%20pedido%E2%80%A6%20%20deseas%20ver%20el%20cat%C3%A1logo%20"
                  target="_blank"
                  rel="noreferrer"
                >
                  <WhatsAppOutlined style={{ fontSize: "40px" }} />
                </a>
              </ContentIcon>
              <ContentIcon md={8} xs={8}>
                <a
                  href="https://www.instagram.com/lenceria_verona/?hl=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramOutlined style={{ fontSize: "40px" }} />
                </a>
              </ContentIcon>{" "}
              <ContentIcon md={8} xs={8}>
                <a
                  href="https://www.instagram.com/lenceria_verona/?hl=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PinterestOutlined style={{ fontSize: "40px" }} />
                </a>
              </ContentIcon>{" "}
            </Row>
          </Col>
        </Row>
      </Col> */}
    </ContentInitialsuscribe>
  );
};

export default FormSubscribe;
