import React, { useContext } from "react";
import FilterFields from "./FilterFields";
import { ProductsContext } from "../products/context/ProductsContext";
import { CardFilterProducts } from "../products/pages/styled";
import { TitlePanelFilters } from "../products/components/style/styleProducts";
import { Row } from "antd";
import { SlidersOutlined, CloseOutlined } from "@ant-design/icons";
import useMovilSize from "../../hooks/useMovilSize";

const ProductsFilterPanel = ({
  initialCategorys,
  modalMode = false,
  hideCategory = false,
  onCloseFilter,
}) => {
  const { searchCustom } = useContext(ProductsContext);
  const { productsFiltered } = searchCustom;
  const { MovilSize } = useMovilSize();

  const categoriesSet = new Set();
  const referencesSet = new Set();

  productsFiltered.forEach((product) => {
    product.category?.forEach((cat) => categoriesSet.add(JSON.stringify(cat)));
    if (product.reference) {
      referencesSet.add(JSON.stringify(product.reference));
    }
  });

  const categories = Array.from(categoriesSet).map((cat) => JSON.parse(cat));
  const references = Array.from(referencesSet).map((ref) => JSON.parse(ref));

  return (
    <>
      <Row justify={"center"}>
        <TitlePanelFilters>
          <SlidersOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
          Filtros
          {!MovilSize ? (
            <CloseOutlined
              onClick={onCloseFilter}
              style={{ fontSize: "14px", marginRight: "10px" , marginLeft:'20px' }}
            />
          ) : null}
        </TitlePanelFilters>
      </Row>

      <CardFilterProducts props={modalMode}>
        <FilterFields
          hideCategory={hideCategory}
          categories={categories}
          references={references}
          initialCategorys={initialCategorys}
        />{" "}
      </CardFilterProducts>
    </>
  );
};

export default ProductsFilterPanel;
