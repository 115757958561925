import React, { useContext, useState } from "react";
import { Col, Row, Button, Typography, Collapse } from "antd";
import { ProductsContext } from "../products/context/ProductsContext";

const { Title } = Typography;
const { Panel } = Collapse;

const FilterFields = ({
  categories = [],
  references = [],
  colors = [],
  initialCategorys = "",
  verticalMenu = false,
  hideCategory,
}) => {
  const {
    searchCustom: { getProductsFiltered },
  } = useContext(ProductsContext);

  const [selectedFilters, setSelectedFilters] = useState({
    category: initialCategorys,
    reference: "",
    color: "",
  });

  console.log(selectedFilters);

  console.log(initialCategorys);
  const handleFilterChange = (filterType, value) => {
    const updatedFilters = {
      ...selectedFilters,
      [filterType]: selectedFilters[filterType] === value ? "" : value,
    };
    setSelectedFilters(updatedFilters);
    getProductsFiltered(updatedFilters);
  };

  const renderFilterOptions = (type, items) => (
    <Row gutter={[8, 8]}>
      {items.map((item) => (
        <Col key={item._id || item.name} span={8} xs={8} sm={12} md={8}>
          <Button
            type={
              selectedFilters[type] === (item._id || item.name)
                ? "primary"
                : "default"
            }
            block
            onClick={() => handleFilterChange(type, item._id || item.name)}
          >
            {item.name}
          </Button>
        </Col>
      ))}
    </Row>
  );

  const renderFilterGroup = (title, type, items) => (
    <Panel
      header={
        <span
          style={{
            fontSize: "16px",
            fontFamily: "Arial, sans-serif",
            justifyContent: "start",
          }}
        >
          {title}
        </span>
      }
      key={type}
    >
      {renderFilterOptions(type, items)}
    </Panel>
  );

  return (
    <Col xs={24} md={24} style={{ padding: "0px" }}>
      <Collapse
        style={{
          display: !verticalMenu ? "block" : "flex",
          flexDirection: !verticalMenu ? "column" : "row",
          border: "none",
          background: "transparent",
        }}
      >
        {!hideCategory &&
          renderFilterGroup(
            "Categorías",
            "category",
            categories.filter(
              ({ name }) => !["Deluxe", "Nueva colección"].includes(name)
            )
          )}

        {/* Renderizar referencias */}
        {renderFilterGroup("Referencias", "reference", references)}

        {/* Renderizar colores */}
        {renderFilterGroup("Colores", "color", colors)}
      </Collapse>
    </Col>
  );
};

export default FilterFields;
