import { Row } from "antd";
import styled from "styled-components";

const ContentProducts = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-items: end;
  justify-content: center;
  margin-bottom: 30px;
  font-size: ${(props) => (props.MovilSize ? "2rem" : "1rem")};

  /* font-family: "Baloo Tamma 2", system-ui; */
  font-family: Montserrat, sans-serif;

  text-transform: uppercase;
  font-weight: 800;
  line-height: 40px;
  font-size: 1.1rem;
  padding: 5px;
  margin-left: 0px !important;
  button {
    margin: 20px auto;
    align-items: center;
    display: flex;
    width: 100px;
    min-height: 30px;
    font-size: 10px;
    height: 40px;
    color: black;
    background-color: white;
  }
  .btnFilter {
    border: none;
    background-color: transparent;
    text-align: center;
    justify-content: center;
  }
`;

const ContentImgProduct = styled(Row)`
  border-radius: 7px;
  border-color: transparent;
  border: 5px;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;

  img {
    border: 5px;
    border-radius: 7px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transition: transform 5s ease-in-out;

    /* min-height: ${(props) => (props.responsive ? "210px" : "340px")};
    max-height: ${(props) => (props.responsive ? "210px" : "340px")};
    min-width: ${(props) => (props.responsive ? "210px" : "14rem")}; */
    border: 5px;
    border-radius: 7px;

    transition: transform 0.4s ease;
  }

  :hover img {
    transform: scale(1.03);
  }
`;

// const ImageContainer = styled(Row)`
//   width: 100%;
//   min-height: ${(props) => (props.responsive ? "250px" : "380px")};
//   overflow: hidden;
// `;

const ImageContainer = styled(Row)`
  position: relative;
  width: 100%;
  min-height: ${(props) => (props.responsive ? "250px" : "380px")};

  overflow: hidden;
`;

const RowNew = styled.div`
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 23px;
  width: 100px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
  text-align: center;
  border-radius: 2px;
  margin-top: 20px;
  margin-left: 10px;
`;
const NewLabel = styled.span`
  font-weight: bold;
  color: white;
  font-size: clamp(0.6rem, 0.5vw, 1rem) !important;
`;

const RowOff = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-height: 23px;
  width: 100px;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
  text-align: center;
  border-radius: 2px;
  margin-top: 20px;
  margin-right: 10px;
  align-content: center;

  .icon {
    justify-content: center;
    size: 10px !important;
    margin-left: 3px;
    color: gray;
    width: 14px;
  }
`;

const RowAddToCart = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  background: transparent;
  color: black;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0;
  overflow: hidden;
  text-align: center;

  margin-right: 0px;
  align-content: center;

  /* .icon {
    justify-content: end;
    size: 10px !important;
    margin-left: 3px;
    color: gray;
    width: 14px;
  } */
`;

const LabelOff = styled.span`
  font-weight: bold;
  color: black;
  font-size: clamp(0.6rem, 0.5vw, 1rem) !important;
`;

const CardProduct = styled(Row)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  img {
    /* width: 100%;
    height: 100%;
    border-radius: 7px;
    min-height: ${(props) => (props.responsive ? "510px" : "100%")};
    max-height: ${(props) => (props.responsive ? "510px" : "100%")}; */
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen cubra todo el espacio disponible sin distorsionarse */
    border-radius: 2px;
    min-height: ${(props) => (props.responsive ? "250px" : "380px")};
    max-height: ${(props) => (props.responsive ? "250px" : "380px")};

  }

  /* border: 2px solid #f1f1f1; */
  border-radius: 10px;
  cursor: pointer;
  :hover {
    /* -webkit-box-shadow: 1px 10px 14px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 10px 14px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 10px 14px -5px rgba(0, 0, 0, 0.75); */
  }

  span {
    text-align: start;

    /* font-family: "Baloo Tamma 2", system-ui; */
    /* Montserrat Bold */
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-style: normal;

    font-weight: bold;
    font-size: 1.1rem;
  }
`;

const TitleContentProduct = styled.span`
  /* margin-top: 50px; */

  font-size: 30px;
  font-size: clamp(0.9rem, 2.5vw, 2rem);

  font-weight: bold;
  text-align: center;

  margin-top: 20px;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  /* line-height: 40px; */
`;

const DescriptionDetail = styled.span`
  /* margin-top: 50px; */
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-size: clamp(0.9rem, 2.5vw, 1.4rem);

  font-weight: bold;
  text-align: center;

  font-family: Montserrat, sans-serif;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 1000;
  line-height: 40px;
  margin-bottom: 10px;
`;

const TitlePanelFilters = styled.span`
  font-family: "Montserrat", sans-serif;
  font-family: Montserrat, sans-serif;

  text-transform: uppercase;
  font-weight: 800;
  line-height: 40px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

const ContenCustom = styled(Row)`
  /* background: #E3E1D9; */
  background: white;
  /* margin-top: 60px; */
  /* min-width: 630px; */
  .title {
    background-color: aliceblue;
  }
`;

const ItemName = styled.span`
  /* font-size: 0.9 !important; */
  /* margin-top: 10px; */
  font-size: 0.8rem !important;
`;

const ItemPrice = styled.span`
  color: ${(props) => (props.PriceOff ? "red" : "#919191")};
  font-size: 0.8rem !important;
  text-decoration: ${(props) => (props.PriceOff ? "line-through" : "none")};
  margin-right: 10px;
`;

const ItemPriceOff = styled.span`
  color: black;
  font-size: 0.8rem !important;
  color: #919191;
`;

const VeronaText = styled.span`
  margin-top: 10px;
  font-size: 1.1rem !important;
  color: #919191;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.9s ease;

  button {
    display: flex;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
    max-width: 45px;
    border-radius: 7px;
    box-shadow: none;
    background: transparent;
    border: none;
  }
  ${ContentImgProduct}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export {
  CardProduct,
  ContentProducts,
  ContentImgProduct,
  ItemName,
  ItemPrice,
  TitleContentProduct,
  ContenCustom,
  ButtonsContainer,
  VeronaText,
  ImageContainer,
  ItemPriceOff,
  NewLabel,
  RowNew,
  TitlePanelFilters,
  RowOff,
  DescriptionDetail,
  LabelOff,
  RowAddToCart,
};
