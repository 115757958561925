import React, { useContext } from "react";
import { ContentSearchModal, ContentSearchNav } from "./style";
import { Col, Drawer, Row } from "antd";
import SearchCustom from "../searchCustom/SearchCustom";
import ProductsFilterPanel from "../productsFilterPanel/ProductsFilterPanel";
import CarouselProducts from "../carouselProducts/components/CarouselProducts";
import { ProductsContext } from "../products/context/ProductsContext";
import CarouselProductsSearch from "../carouselProductsSearch/components/CarouselProductsSearch";
import useMovilSize from "../../hooks/useMovilSize";

const SearchCustomNav = ({ onCloseModalSearch, showContenSearch }) => {
  const { searchCustom } = useContext(ProductsContext);
  const { productsFiltered } = searchCustom;
  const { MovilSize } = useMovilSize();

  const onClosSearch = () => {
    onCloseModalSearch(false);
  };

  return (
    <>
      <Col md={12} xs={0} />

      <Col md={12} xs={24} style={{ border: "none" }}>
        <ContentSearchNav>
          <Drawer
            title={null}
            placement="top"
            headerStyle={{ display: "none", border: "none" }}
            closable={false}
            onClose={onClosSearch}
            visible={showContenSearch}
            getContainer={false}
            style={{
              position: "absolute",
              background: "white",
              border: "none",
              height: MovilSize ? "600px" : "400px",
            }}
          >
            {/* Contenido del Drawer */}
            <Col xs={24} md={24}>
              <ContentSearchModal>
                <Col xs={24} md={12}>
                  <SearchCustom modePage />
                </Col>
              </ContentSearchModal>
            </Col>

            <Col xs={24} md={12}>
              <Row>
                <Col xs={24} md={10}>
                  <ProductsFilterPanel />
                </Col>

                <Col xs={24} md={14}>
                  <Row style={{ marginTop: MovilSize ? "0px" : "60px" }}>
                    <CarouselProductsSearch items={productsFiltered} />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Drawer>
        </ContentSearchNav>
      </Col>
    </>
  );
};

export default SearchCustomNav;
