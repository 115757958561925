import { Button, Row } from "antd";
import styled from "styled-components";

const ContentAddBtn = styled.div``;

const ButtonAddToCartStyled = styled(Button)`
  display: flex;
  min-height: 30px;
  color: black;
  align-items: center;
  justify-content: center;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  border-color: transparent;
  background-color: transparent !important;
  :hover {
    border: 1px solid #75777e !important;
    color: #75777e !important;
  }
`;

const ButtonCustomStyle = styled(Button)`
  width: 100% !important;
`;

export { ButtonAddToCartStyled, ContentAddBtn, ButtonCustomStyle };
