import { Button, Row } from "antd";
import styled from "styled-components";

const ContentSubscribeFooter = styled(Row)`
  font-family: "Baloo Tamma 2", system-ui;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 30px;

  span {
    font-size: 20px !important;
  }
  input {
    color: white !important;
    border-radius: 5px;
    background-color: transparent;
    min-height: 3rem !important;
    ::placeholder {
      color: #888;
    }
  }
`;

const ButtonSubscribe = styled(Button)`
  margin-top: 10px;
  min-height: 2rem !important;
  border-radius: 5px;
  width: 100%;
  background-color: black;
  background-color: white;
  min-height: 3rem !important;
  font-family: "Baloo Tamma 2", system-ui;
  color: black;
  span {
    font-size: 17px !important;
  }
  :hover {
    background: #dee2e6;
    color: black !important;
    border: 1px solid white !important;
  }
`;

const ContentInitialsuscribe = styled(Row)`
  margin: auto !important;
`;

const TitleFormSuscribe = styled.span`
  color: white;
  /* font-size: ${(props) => (props.MovilSize ? "2rem" : "1rem")}; */
  font-family: Montserrat, sans-serif;
`;

const DescriptionFormSuscribe = styled.p`
  color: white;
  /* font-size: ${(props) => (props.MovilSize ? "1rem" : "0.5rem")}; */
  font-family: Montserrat, sans-serif;
  font-size: 0.8rem !important;
`;

export {
  ContentSubscribeFooter,
  ButtonSubscribe,
  ContentInitialsuscribe,
  TitleFormSuscribe,
  DescriptionFormSuscribe,
};
