import { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import {
  CarouselControl,
  CarouselInner,
  CarouselItem,
  CarouselWrapper,
  ContentNameCategory,
} from "./styled";
import useMovilSize from "../../../hooks/useMovilSize";

const CarouselCategorys = ({ items, autoPlay = true, autoPlayTime = 5000 }) => {
  const { MovilSize } = useMovilSize();
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const itemsToShow = MovilSize ? 1 : 5;
  const totalItems = items?.length || 0;
  const uploadsPath = process.env.REACT_APP_IMAGES_URL;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
  };

  const redirectToProductDetails = (item) => {
    navigate(`/categorys/${item._id}/${item.name}`);
  };

  useEffect(() => {
    if (autoPlay && totalItems > 1) {
      const interval = setInterval(nextSlide, autoPlayTime);
      return () => clearInterval(interval);
    }
  }, [autoPlay, autoPlayTime, totalItems]);

  return (
    <>
      {MovilSize ? (
        <>
          <CarouselWrapper>
            <CarouselInner
              style={{
                transform: `translateX(-${
                  currentIndex * (100 / itemsToShow)
                }%)`,
                width: `${(totalItems * 100) / itemsToShow}%`,
              }}
            >
              {items?.map((item) => (
                <CarouselItem
                  key={item._id}
                  style={{
                    flex: `0 0 ${100 / itemsToShow}%`,
                  }}
                >
                  <Col xs={24}>
                    <Row>
                      <Col xs={24}>
                        <Row justify={'center'}>
                          <img
                            src={`${uploadsPath}${item?.imgs[0]?.url}`}
                            alt={item.description}
                            onClick={() => redirectToProductDetails(item)}
                          />
                        </Row>
                      </Col>

                      <Col xs={24}>
                        <ContentNameCategory MovilSize={MovilSize}>
                          <Row justify={"center"}>
                            <span>{item.name}</span>
                          </Row>
                        </ContentNameCategory>
                      </Col>
                    </Row>
                  </Col>
                </CarouselItem>
              ))}
            </CarouselInner>
            <CarouselControl prev onClick={prevSlide}>
              <LeftOutlined />
            </CarouselControl>
            <CarouselControl next onClick={nextSlide}>
              <RightOutlined />
            </CarouselControl>
          </CarouselWrapper>
        </>
      ) : (
        <>
          <CarouselWrapper>
            <CarouselInner
              style={{
                transform: `translateX(-${
                  currentIndex * (100 / itemsToShow)
                }%)`,
                width: `${(totalItems * 100) / itemsToShow}%`,
              }}
            >
              {items?.map((item) => (
                <>
                  <div>
                    <CarouselItem
                      key={item._id}
                      style={{
                        flex: `0 0 ${100 / itemsToShow}%`,
                      }}
                    >
                      <img
                        src={`${uploadsPath}${item?.imgs[0]?.url}`}
                        alt={item.name}
                        onClick={() => redirectToProductDetails(item)}
                      />
                    </CarouselItem>

                    <ContentNameCategory MovilSize={MovilSize}>
                      <Row justify={"center"}>
                        <span>{item.name}</span>
                      </Row>
                    </ContentNameCategory>
                  </div>
                </>
              ))}
            </CarouselInner>
            <CarouselControl prev onClick={prevSlide}>
              <LeftOutlined />
            </CarouselControl>
            <CarouselControl next onClick={nextSlide}>
              <RightOutlined />
            </CarouselControl>
          </CarouselWrapper>
        </>
      )}
    </>
  );
};

export default CarouselCategorys;
