import { Row } from "antd";
import styled from "styled-components";

const ContentSlider = styled(Row)`
  margin: auto;
  margin-top: 20px;
  width: 100%;
  height: 500px;
  margin-bottom: 200px;
  border: none;
  padding: 20px;
  /* min-width: 768px; */
  /* min-width: 630px; */


  @media (max-width: 768px) {
    /* height: 300px; */
    width: 100%;
  }
`;

const ContentSliderVideo = styled(Row)`
  margin-bottom: 10px;
  /* width: 100%; */
  /* min-width: 630px; */
  width: 100%;

  /* video {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    width: 100%;
    height: 33rem;
    @media (max-width: 768px) {
      height: 400px;
      width: 100%;
    }
  } */
  img {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    width: 100%;
    height: 45rem;
    @media (max-width: 768px) {
      height: 400px;
      width: 100%;
    }
  }
`;

export { ContentSlider, ContentSliderVideo };
