import React, { useRef, useEffect } from "react";
import { ContentSliderVideo } from "./style";
import { Col } from "antd";

const Slider = () => {
  const videoRef = useRef(null);

  // const video = {
  //   url: "https://res.cloudinary.com/doe7vtjfk/video/upload/v1696721762/B1B4806E-9DCC-45F6-AA44-15D8F02E04D5_b2za6u.mov",
  // };

  // useEffect(() => {
  //   const playVideo = () => {
  //     if (videoRef.current) {
  //       videoRef.current.play().catch((error) => {
  //         console.error("Error al reproducir el video:", error);
  //       });
  //     }
  //   };

  //   playVideo();

  //   document.addEventListener("touchstart", playVideo);

  //   return () => {
  //     document.removeEventListener("touchstart", playVideo);
  //   };
  // }, []);

  return (
    <Col xs={24} md={24}>
      <ContentSliderVideo justify="center">
        <img
          style={{ objectFit: "cover" }}
          src={`https://res.cloudinary.com/doe7vtjfk/image/upload/v1736296996/WhatsApp_Image_2025-01-07_at_7.41.37_PM_c3pxfi.jpg`}
          alt={""}
        />
      </ContentSliderVideo>
    </Col>
  );
};

export default Slider;
